.puzzle {
    display: grid;
    grid-template-columns: repeat(var(--nombre-pieces), 1fr);
    grid-template-rows: repeat(var(--nombre-pieces), 1fr);
    gap: 1px;
    width: calc(var(--container-size) * 1px);
    height: calc(var(--container-size) * var(--ratio-image) * 1px);
  }

  .puzzle__pieces--container {
    width: calc(var(--container-size) / var(--nombre-pieces) * 1px);
    height: calc(var(--container-size) / var(--nombre-pieces) * var(--ratio-image) * 1px);
    background-color: #0003;
  }

