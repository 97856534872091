.piece {
    display: inline-block;
    width: calc(var(--piece-size) * 1px);
    height: calc(var(--piece-size) * 1px);
    margin-right: 20px;
    background-size: calc(100% * var(--nombre-pieces)) calc(100% * var(--nombre-pieces));
    border: 1px white solid;
    cursor: grab;
}

.dragging {
    border: 2px solid green;
}

.puzzle__pieces--container .piece {
    margin-right: 0;
    border: none;
}