:root {
  --blue: #25354e;
  --darkbrown: #292b2a;
  --gold: #c09e5a;
  --white: #fff;
}

* {
  box-sizing: border-box;
  position: relative;

}

html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', Arial, sans-serif;
  background-color: #2f363e;
}
