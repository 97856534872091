.form {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

label {
    font-family: "Satisfy", sans-serif;
    font-size: 20px;
    color: var(--gold);
}

select {
    font-family: "Satisfy", sans-serif;
    font-size: 14px;
    font-weight: bold;
    background-color: var(--gold);
    color: var(--darkbrown);
    padding: 4px 8px;
    border-radius: 4px;
    margin-left: 10px;
}

input[type="file"] {
    font-family: "Satisfy", sans-serif;
    font-size: 14px;
    font-weight: bold;
    background-color: var(--gold);
    color: var(--darkbrown);
    padding: 4px 8px;
    border-radius: 4px;
    margin-bottom: 30px;
}

.buttonForm {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: "Satisfy", sans-serif;
    font-size: 24px;
    font-weight: bold;
    background-color: var(--gold);
    color: var(--darkbrown);
    padding: 4px 8px;
    border: none;
    border-radius: 4px;
}